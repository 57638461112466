/* table class is necessary to control row not having onClick event */
.row-hover:hover {
  background-color: rgba(0, 175, 238, 0.8) !important;
}
/* 
.highcharts-credits{
  display: none !important;
} */

.report-link-icon {
  width: 30px;
}
